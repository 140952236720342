<template>
    <CForm ref="form" class="needs-validation">
        <CInput
            placeholder="Ioss Number"
            type="text"
            autocomplete="text"
            v-model="data.iossNumber"
        >
            <template #prepend-content>
                <CIcon name="cilTextSquare" />
            </template>
        </CInput>
        <CSelect
            :options="returnWarehouses"
            :value.sync="data.returnWarehouseAddressId"
            placeholder="ReturnWareHouseAddress"
            :required="data.iossNumber && data.iossNumber !== ''"
        >
            <template #prepend-content>
                <CIcon name="cilTextSquare" />
            </template>
        </CSelect>
        <CRow form class="form-group">
            <CCol tag="label" sm="2" class="col-form-label">
                <b>Aktif</b>
            </CCol>
            <CCol sm="2" style="margin-left: -15px">
                <CSwitch
                :checked.sync="data.shippingActiveFlag"
                class="mr-1"
                color="primary"
                size="lg"
                 />
            </CCol>
            <CCol tag="label" sm="4" class="col-form-label"> </CCol>
        </CRow>
    </CForm>
</template>

<script>
   export default{
       name: "CountriesForm",
       props:{
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       computed:{
            returnWarehouses() {
                let data = [] 
                this.$store.getters.returnWarehouses.map(w => data.push({label: w.companyName, value: w.id.toString()}))
                return data
            },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
   }
</script>